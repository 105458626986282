/* Contains the text box and photo box */
.home-box {
  display: flex; 
  flex-direction: row;
  margin-left: 70px;
  margin-right: 70px;
}

/* Contains the title and bio */
.home-text-box {
  flex: 5;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Contains the profile image */
.home-photo-box {
  flex: 3;
  padding: 10px;

  /* Used to center the profile image */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Name text */
.home-name {
  font-size: 24px;
  font-weight: 500;
  color: black;
}

/* Title text */
.home-title {
  font-size: 60px;
  font-weight: 700;
  padding: 0;
  line-height: normal;

  margin-top: 20px;
  margin-bottom: 20px;
}

/* Bio text */
.home-bio {
  font-size: 24px;
  color: black;
}

/* LinkedIn and GitHub box */
.home-link-box {
  padding-top: 10px;
}

/* LinkedIn and GitHub Icons */
.home-link-icon {
  height: 40px;
  width: 40px;
  display: inline;
  margin-top: 20px;
  margin-right: 20px;
}

/* When the page width is under 1024 pixels, apply these styles */
@media (max-width: 1024px) {

  /* Decrease Margins */
  .home-box {
    margin-left: 50px;
    margin-right: 50px;
  }

  /* Decrease title size */
  .home-title {
    font-size: 40px;
  }

  /* Decrease bio size */
  .home-bio {
    font-size: 20px;
  }
}

/* When the page width is under 768 pixels, apply these styles */
@media (max-width: 768px) {

  /* Display the photo above the title */
  .home-box {
    flex-direction: column-reverse;
    margin-left: 10px;
    margin-right: 10px;
  }

  /* Decrease title size */
  .home-title {
    font-size: 34px;
  }

  /* Add padding to make image smaller */
  .home-photo-box {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
  }
  
}


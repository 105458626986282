/* Contains the image box and text box */
.education-box {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.education-text-box {
  flex: auto;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 20px;
}

.education-image-box {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 150px;
  width: 150px;
  min-height: 150px;
  min-width: 150px;
}

.education-school {
  font-size: 26px;
  font-weight: 600;
}

.education-degree {
  font-size: 22px;
  font-weight: 500;
}

.education-location {
  font-size: 20px;
}

.education-year {
  font-size: 20px;
}



/* When the page width is under 768 pixels, apply these styles */
@media (max-width: 768px) {

  /* Reduce the images to 100 pixels */
  .education-image-box {
    height: 100px;
    width: 100px;
    min-height: 100px;
    min-width: 100px;
  }

  .education-school {
    font-size: 22px;
    font-weight: 600;
  }
  
  .education-degree {
    font-size: 19px;
    font-weight: 500;
  }
  
  .education-location {
    font-size: 16px;
  }
  
  .education-year {
    font-size: 16px;
  }
}


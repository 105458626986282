.projects-box {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.projects-text-box {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 20px;
  flex: 1;
}

.projects-image-box {
  flex: 1;
  border: 1px solid lightgray;
}

.projects-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.projects-description {
  font-size: 20px;
  margin-bottom: 20px;
}

.projects-link {
  font-size: 20px;
  font-weight: 600;

  /* Used for underline on hover: */
  display: inline-block;
  position: relative;
}

/* Media query */
@media (max-width: 768px) {

  .projects-box {
    flex-direction: column;
  }

  .projects-text-box {
    padding-top: 15px;
    padding-bottom: 30px;
  }
}

/* Underline on hover animation: */
.projects-link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.projects-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


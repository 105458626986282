.navbar-link {
  margin-left: 1rem; /* 16px */
  margin-right: 1rem; /* 16px */
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 500;

  /* Used for underline on hover: */
  display: inline-block;
  position: relative;
}

/* Underline on hover animation: */
.navbar-link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navbar-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
